import { useColorModeValue } from "@chakra-ui/color-mode";
import { RadioProps, useRadio } from "@chakra-ui/radio";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { IconType } from "react-icons";

export const RadioCard = ({ icon: Icon, children, img, title, onChange,selectedValues, next, ...rest }) => {
  const [isSelected, setIsSelected] = useState(false);
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const checkboxRef = React.useRef(null);

  const bg = useColorModeValue("gray.50", "gray.900");
  const selectedBg = "#44c7bd"; // Color de fondo cuando está seleccionado

  // Llamamos a la función onChange y a nextStep al hacer clic en el RadioCard
  const handleCardClick = () => {
    setIsSelected(!isSelected);
    onChange(rest.value);
    console.log(selectedValues?.[rest.field?.name] , rest?.value)

    console.log(input)
    console.log(rest)
  };

  return (
    <Box
      {...checkbox}
      cursor="pointer"
      ref={checkboxRef}
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      onClick={handleCardClick}
      as="label"
      _focus={{
        boxShadow: "outline",
      }}
      _hover={{
        cursor: "pointer",
      }}
      bg={selectedValues?.[rest.field?.name] === rest?.value ? selectedBg : bg}
      px={8}
      py={4}
    >
      <input {...input} />
      <Flex
        sx={{
          flexDir: "column",
          alignItems: "center",
        }}
      >
        {/* {Icon && (
          <Box sx={{ p: 4 }}>
            <Icon size="48px" />
          </Box>
        )} */}
        {img && (
          <Box sx={{ p: 4 }}>
            <Image src={img} alt="logo" width="100px" />
          </Box>
        )}

        {!title && <Text fontSize="2em" fontWeight="bold">
          {rest.value}
        </Text>
}
        {children}
      </Flex>
    </Box>
  );
};
