import { Box, Heading } from "@chakra-ui/layout";
import { Button, RadioGroup, Stack, Text, useRadioGroup } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import { GiMoneyStack, GiReceiveMoney } from "react-icons/gi";
import * as yup from "yup";
import { ErrorMessage } from "../views/Book";
import { RadioCard } from "../components/RadioCard";
import { useEffect, useState } from "react";

export const Step1Schema = yup.object().shape({
  service: yup.string().required("Este campo es obligatorio."),
});

const options = [
  { label: "1cm a 5cm", value: "XS", icon: GiReceiveMoney },
  { label: "6cm a 10cm", value: "S", icon: GiMoneyStack },
  { label: "11cm a 15cm", value: "M", icon: GiMoneyStack },
  { label: "16cm a 20cm", value: "L", icon: GiMoneyStack },
  { label: "Mayor que 21cm", value: "XL", icon: GiMoneyStack },
];

export const Step1 = ({ nextStep, selectedValues, setSelectedValues }) => {
  const { control } = useFormContext();

  const {
    field,
    formState: { errors },
  } = useController({
    name: "tamaño",
    control,
  });
  useEffect(() => {
    setSelectedValues(field.value);
  }, [field.value]);


  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "tamaño",
    value: selectedValues,
    onChange: (value) => {
      setSelectedValues({
        ...selectedValues,
        tamaño: value,
      });
    },
  });
  const group = getRootProps();
  return (
    <Box
      sx={{
        mb: 8,
        display: "flex",
        flexDir: "column",
        alignItems: "center",
      }}
    >
      <Heading size="lg" sx={{ mt: 8 }}>
        Tamaño del tatuaje
      </Heading>
      <Text sx={{ mt: 4 }}>
        Selecciona el tamaño del tatuaje que deseas
      </Text>
      <RadioGroup mb={4} name="Step1" value={selectedValues} sx={{ mt: 8 }}>
        <Stack {...group} spacing={4} direction={{ base: "column", md: "row" }}>
          {options.map(({ value, label, icon }, i) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard
                icon={icon}
                key={value}
                field={field}
                {...radio}
                selectedValues={selectedValues}
                onChange={() => {
                  setSelectedValues({
                    ...selectedValues,
                    tamaño: value,
                  });
                }}
              >
                {label}
              </RadioCard>
            );
          })}
        </Stack>
      </RadioGroup>
      <Box mt={4}>
        {/* Agregamos el botón Siguiente */}
        <Button onClick={nextStep}>Siguiente</Button>
      </Box>
      {errors.service && (
        <ErrorMessage message={errors.service.message || ""} />
      )}
    </Box>
  );
};
