import Book from './views/Book';
import { Route, Routes } from "react-router-dom";
import './styles.css';
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Book />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
