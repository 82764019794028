import React from "react";
import { useForm, Controller, useController, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { Button, FormControl, FormLabel, Input, Box, FormErrorMessage, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";


export const Step6Schema = yup.object().shape({
  service: yup.string().required("Este campo es obligatorio."),
});

export const Step6 = ({ nextStep, selectedValues, setSelectedValues }) => {
  const methods = useFormContext();

  const { control } = useFormContext();
  const { field, formState: { errors } } = useController({ name: "datos", control });

  // Función para manejar la acción de enviar el formulario
  const onSubmit = (data) => {
    // Verificamos que el nombre tenga más de 3 caracteres antes de enviar el formulario
    if (selectedValues.nombre && selectedValues.nombre.length <= 3) {
      // Mostramos una alerta de error si el nombre es inválido
      methods.setError("datos", {
        type: "manual",
        message: "El nombre debe tener más de 3 caracteres.",
      });
    } else {
      // Aquí puedes hacer algo con los datos enviados (por ejemplo, guardarlos en el estado del componente padre)
      console.log(data);
      // Luego, avanzamos al siguiente paso
      nextStep();
    }
  };

  return (
    <Box
      sx={{
        mb: 8,
        display: "flex",
        flexDir: "column",
        alignItems: "center",
      }}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.datos}>
          <FormLabel>Tu nombre</FormLabel>
          <Controller
            name="datos"
            control={methods.control}
            placeholder="Escribe tu nombre"
            rules={{ required: "Este campo es obligatorio." }}
            render={({ field }) => (
              <Input
                {...field}
                value={selectedValues.nombre}
                onChange={(e) => {
                  setSelectedValues({
                    ...selectedValues,
                    nombre: e.target.value,
                  });
                }}
              />
            )}
          />
          {errors.datos && (
            <FormErrorMessage>{errors.datos.message}</FormErrorMessage>
          )}
        </FormControl>

        {/* Agregamos la alerta para indicar el error */}
        {selectedValues.nombre && selectedValues.nombre.length <= 3 && (
          <Alert status="error" mt={2}>
            <AlertIcon />
            <AlertDescription>
              El nombre debe tener más de 3 caracteres.
            </AlertDescription>
          </Alert>
        )}
      </form>
    </Box>
  );
};
