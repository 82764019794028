import { useState } from "react";
import { Box, Heading } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useRadioGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { ErrorMessage, FormValues } from "../views/Book";
import { RadioCard } from "../components/RadioCard";

export const Step5Schema = yup.object().shape({
  service: yup.string().required("Este campo es obligatorio."),
});

export const Step5 = ({ nextStep, selectedValues, setSelectedValues }) => {
  const { control } = useFormContext();
  const { field, formState: { errors } } = useController({ name: "diseño", control });

  // Agregamos el estado local para controlar la opción "No tengo" y la imagen seleccionada
  const [noDesignSelected, setNoDesignSelected] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "diseño",
    defaultValue: field.value,
    onChange: (value) => {
      setSelectedValues({
        ...selectedValues,
        diseno: value,
      });
      if (selectedImage) {
        field.onChange(selectedImage);
      } else {
        // Si selecciona "No tengo", actualizamos el estado local y reseteamos la imagen seleccionada
        if (value === "") {
          setNoDesignSelected(true);
          setSelectedImage("");
        } else {
          setNoDesignSelected(false);
        }
      }
    },
  });

  const group = getRootProps();

  const isDescriptionValid = selectedValues.diseno && selectedValues.diseno.length > 25;

  return (
    <Box
      sx={{
        mb: 8,
        display: "flex",
        flexDir: "column",
        alignItems: "center",
      }}
    >
      <Heading size="lg" sx={{ mt: 8 }}>
        Explícanos tu idea
      </Heading>
      <Text sx={{ mt: 4 }}>Descripción, nos ayudará a plasmar tu idea</Text>

      {/* Mostramos la imagen seleccionada */}
      {selectedImage && (
        <Box mt={4}>
          <img src={selectedImage} alt="Selected" width="200" />
        </Box>
      )}

      {/* Mostramos el TextArea solo si selecciona la opción "No tengo" */}
      <FormControl justifyContent="center" alignItems="center"
           onChange={
                    (e) => {
                      setSelectedValues({
                        ...selectedValues,
                        diseno: e.target.value
                      });
                    }
                  
      }>
        <FormLabel>Descripción (máximo 400 caracteres)</FormLabel>
        <Textarea
          maxLength={400}
          resize="vertical"
          placeholder="Escribe aquí tu idea"
          value={selectedValues.diseno}
          {...field}
          color="black"
        />
      </FormControl>
      <span>{selectedValues.diseno?.length}/400</span>

      {/* Agregamos la alerta para indicar el error */}
      {selectedValues.diseno && selectedValues.diseno.length <= 25 && (
        <Alert status="error" mt={2}>
          <AlertIcon />
          {/* <AlertTitle>Error</AlertTitle> */}
          <AlertDescription>La descripción debe tener más de 25 caracteres.</AlertDescription>
        </Alert>
      )}

      {/* Agregamos el botón Siguiente */}
      <Button mt={4} onClick={nextStep} isDisabled={!isDescriptionValid}>
        Último paso
      </Button>

      {errors.homeType && <ErrorMessage message={errors.homeType.message || ""} />}
    </Box>
  );
};
