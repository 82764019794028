import { Box, Heading } from "@chakra-ui/layout";
import { Button, RadioGroup, Stack, Text, useRadioGroup } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import { GiBirdHouse, GiGreenhouse, GiSydneyOperaHouse } from "react-icons/gi";
import * as yup from "yup";
import { ErrorMessage, FormValues } from "../views/Book";
import { RadioCard } from "../components/RadioCard";
import color1 from "../assets/img/color/color1.png";
import color2 from "../assets/img/color/color2.png";
import color3 from "../assets/img/color/color3.png";

export const Step4Schema = yup.object().shape({
  service: yup.string().required("Este campo es obligatorio."),
});

const options = [
  { label: "Color", value: "color", img: color1 },
  { label: "Negro", value: "negro", img: color2 },
  { label: "No sé", value: "nose", img: color3 },
];

export const Step4 = ({nextStep, selectedValues, setSelectedValues}) => {

  const { control } = useFormContext();
  const { field, formState: { errors } } = useController({ name: "color", control });


  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "color",
    value: selectedValues,
    onChange: (value) => {
      setSelectedValues({
        ...selectedValues,
        color: value,
      });
    },
  });

  const group = getRootProps();

  return (
    <Box
      sx={{
        mb: 8,
        display: "flex",
        flexDir: "column",
        alignItems: "center",
      }}
    >
      <Heading size="lg" sx={{ mt: 8 }}>
        Cuéntanos el color
      </Heading>
      <Text sx={{ mt: 4 }}>
        A color, negro... o ¿no lo tienes claro?
      </Text>
      <RadioGroup mb={4} name="Step2" sx={{ mt: 8 }}>
        <Stack {...group} spacing={4} direction={{ base: "column", md: "row" }}>
          {options.map(({ value, label, icon, img}, i) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard
                  key={i}
                  children={label}
                  field={field}
                  {...radio}
                  img={img}
                  selectedValues={selectedValues}
                  title={true}
                  onChange={
                    () => {
                      setSelectedValues({
                        ...selectedValues,
                        color: value
                      });
                    }
                  }

                />
            );
          })}
        </Stack>
      </RadioGroup>
      <Box mt={4}>
        {/* Agregamos el botón Siguiente */}
        <Button onClick={nextStep}>Siguiente</Button>
      </Box>
      {errors.homeType && (
        <ErrorMessage message={errors.homeType.message || ""} />
      )}
    </Box>
  );
};