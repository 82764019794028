import { useState, useEffect } from "react";
import { Box, Heading } from "@chakra-ui/layout";
import { Button, RadioGroup, Stack, Text, useRadioGroup } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { ErrorMessage, FormValues } from "../views/Book";
import { RadioCard } from "../components/RadioCard";
import zona1 from "../assets/img/zonas/descarga (1).png";
import zona2 from "../assets/img/zonas/descarga (2).png";
import zona3 from "../assets/img/zonas/descarga (3).png";
import zona4 from "../assets/img/zonas/descarga (4).png";
import zona5 from "../assets/img/zonas/descarga (5).png";
import zona6 from "../assets/img/zonas/descarga (6).png";
import zona7 from "../assets/img/zonas/descarga (7).png";
import zona8 from "../assets/img/zonas/descarga (8).png";
import zona9 from "../assets/img/zonas/descarga (9).png";
import zona10 from "../assets/img/zonas/descarga (10).png";
import zona11 from "../assets/img/zonas/descarga (11).png";
import zona12 from "../assets/img/zonas/descarga (12).png";

export const Step2Schema = yup.object().shape({
  service: yup.string().required("Este campo es obligatorio."),
});

const options = [
  { label: "Pecho", value: "pecho", img: zona1, 
    specificOptions: [
      { label: "Superior", value: "superior" },
      { label: "Esternón", value: "esternon" },
      { label: "Abdominal", value: "abdominal" },
      { label: "No sé", value: "nose" },
    ] 
  },
  { label: "Brazo", value: "brazo", img: zona2, 
    specificOptions: [
      { label: "Manga", value: "manga" },
      { label: "Media manga (muñeca a codo)", value: "media" },
      { label: "Media manga (codo a hombro)", value: "media2" },
      { label: "Biceps", value: "biceps" },
      { label: "Triceps", value: "triceps" },
      { label: "Codo", value: "codo" },
      { label: "Antebrazo", value: "antebrazo" },
      { label: "Muñeca", value: "muneca" },
      { label: "No sé", value: "nose" },
    ]
  },
  { label: "Hombro", value: "hombro", img: zona3,
    specificOptions: [
      { label: "Lateral", value: "hombro lateral" },
      { label: "Detrás", value: "hombro detras" },
      { label: "Delante", value: "hombro delante" },
      { label: "No sé", value: "hombro - nose" },
    ]
  },
  { label: "Espalda", value: "espalda", img: zona4,
    specificOptions: [
      { label: "Escapula", value: "escapula" },
      { label: "Columna", value: "columna" },
      { label: "Lumbar", value: "lumbar" },
      { label: "Costillas", value: "costillas" },
      { label: "Espalda completa", value: "espalda completa" },
      { label: "No sé", value: "espalda - nose" },
    ]
  },

  { label: "Pierna", value: "pierna", img: zona5,
    specificOptions: [
      { label: "Muslo", value: "muslo" },
      { label: "Contra muslo", value: "contra muslo" },
      { label: "Rodilla", value: "rodilla" },
      { label: "Espinilla", value: "espinilla" },
      { label: "Gemelo", value: "gemelo" },
      { label: "No sé", value: "pierna - nose" },
    ]
  },
  { label: "Mano", value: "mano", img: zona6,
    specificOptions: [
      { label: "Dorso", value: "dorso" },
      { label: "Palma", value: "palma" },
      { label: "Dedos", value: "dedos" },
      { label: "No sé", value: "mano - nose" },
    ]
  },
  { label: "Pie", value: "pie", img: zona7,
    specificOptions: [
      { label: "Tobillo", value: "tobillo" },
      { label: "Empeine", value: "empeine" },
      { label: "Dedos", value: "dedos" },
      { label: "No sé", value: "pie - nose" },
    ]
  },
  { label: "Gluteo", value: "gluteo", img: zona8, specificOptions: [] },
  { label: "Ingle", value: "ingle", img: zona9 , specificOptions: []},
  { label: "Cuello", value: "cuello", img: zona10,
    specificOptions: [
      { label: "Lateral", value: "lateral" },
      { label: "Garganta", value: "garganta" },
      { label: "Nuca", value: "nuca" },
      { label: "No sé", value: "cuello - nose" },
    ]
  },
  { label: "Cabeza", value: "cabeza", img: zona11, 
    specificOptions: [
      { label: "Frente", value: "frente" },
      { label: "Barbilla", value: "barbilla" },
      { label: "Orerja", value: "oreja" },
      { label: "Boca", value: "boca" },
      { label: "Nariz", value: "nariz" },
      { label: "No sé", value: "cabeza - nose" },
    ]
  },
  { label: "No sé", value: "nose", img: zona12 },
];

export const Step2 = ({nextStep, selectedValues, setSelectedValues}) => {
  const { control } = useFormContext();

  const {field, formState: { errors }} = useController({name: "zona", control});
    const { getRootProps, getRadioProps } = useRadioGroup({
    name: "zona",
    value: selectedValues,
    onChange: (value) => {
      setSelectedValues({
        ...selectedValues,
        zona: value,
      });
    },
  });
  return (
    <Box
      sx={{
        mb: 8,
        display: "flex",
        flexDir: "column",
        alignItems: "center",
      }}
    >
      <Heading size="lg" sx={{ mt: 8 }}>
        ¿Dónde te gustaría el tatuaje?
      </Heading>
      <Text sx={{ mt: 4 }}>
        Selecciona la zona donde te gustaría el tatuaje
      </Text>
      
      <RadioGroup mb={4} name="Step2" sx={{ mt: 8 }}>
        <Stack spacing={4} direction={{ base: "column", md: "row" }}>
          {options.map(({ value, label, img }, i) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard
                key={value}
                img={img}
                field={field} // Pasamos el campo "field" como prop
                selectedValues={selectedValues}
                {...radio}
                title={true}
                onChange={
                  () => {
                    setSelectedValues({
                    ...selectedValues,
                    zona: value
                });
                }}
              >
                {label}
              </RadioCard>
            );
          })}
        </Stack>
        <Box mt={4}>
        {/* Agregamos el botón Siguiente */}
        <Button onClick={nextStep}>Siguiente</Button>
      </Box>
      </RadioGroup>
      {errors.homeType && (
        <ErrorMessage message={errors.homeType.message || ""} />
      )}
    </Box>
  );
};
