import React from "react";
import { Box, Container, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import logo from "../../assets/img/logo.png";
import packageJson from "../../../package.json";

const Footer = () => {
  const year = new Date().getFullYear();
  const version = packageJson.version;
  const bg = useColorModeValue("gray.50", "gray.900");

  return (
    <>
      <Box className="footer" bg={bg} color="black" textAlign="center" py={4} mt={4}>
        <Container maxW="container.lg" p={4}>
            {/* <Box flex="1" textAlign="left" mb={4} order={{ base: 1, md: 1 }}>
              <img src={logo} alt="logo" width={250} />
            </Box> */}
            {/* <Box flex="1" textAlign={{ base: "center", md: "left" }} mb={4} order={{ base: 0, md: 1 }}>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li>
                  <Text as="b">Contacto</Text>
                  <br />
                  <a href="tel:+34 722 835 293">+34 722 835 293</a>
                  <br />
                  <a href="mailto:karnivoromalaga@gmail.com">karnivoromalaga@gmail.com</a>
                </li>
                <li mt={3}>
                  <Text as="b">Dirección</Text>
                  <br />
                  <a href="https://goo.gl/maps/X7oYzMqgvsqbyU8y5">
                    Av. Reyes Católicos N10 29130 Alhaurín de la Torre, España
                  </a>
                </li>
              </ul>
            </Box> */}
            <Box flex="1" textAlign="center" mb={4} order={{ base: 2, md: 2 }}>
              <iframe
                title="maps"
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d336.4854828882054!2d-4.678693496307463!3d36.64545983856644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2ses!4v1691359320240!5m2!1ses!2ses"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
        </Container>
      </Box>
      <Box bg="light" color="black" textAlign="center" py={2}>
        © {year} Copyright by{" "}
        <a href="https://aleburgos.es" className="text-primary">
          Sawer Corp
        </a>{" "}
        All rights reserved.
      </Box>
      <Box bg="light" color="#b874565e" textAlign="center" py={1}>
        <small>version {version}</small>
      </Box>
    </>
  );
};

export default Footer;
