import React, { useState } from "react";
import { Box, Button, Flex, Heading, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { GiRocketFlight } from "react-icons/gi";
import * as yup from "yup";
import logo from "../assets/img/logo.png";
import qs from "qs";

import { Step1, Step1Schema } from "../components/Step1";
import { Step2, Step2Schema } from "../components/Step2";
import { Step3, Step3Schema } from "../components/Step3";
import { Step4, Step4Schema } from "../components/Step4";
import { Step5, Step5Schema } from "../components/Step5";
import { Step6, Step6Schema } from "../components/Step6";

const INITIAL_VALUES = {
  tamaño: "",
  zona: "",
  zona1: "",
  color: "",
  diseno: "",
  descripcion: "",
  nombre: "",
};

export const ErrorMessage = ({ message }) => {
  return (
    <Box
      sx={{
        p: 2,
        rounded: "md",
        boxShadow: "sm",
        bg: useColorModeValue("gray.50", "gray.800"),
      }}
    >
      <Text fontSize="md" color="red.400" fontWeight={"bold"}>
        {message}
      </Text>
    </Box>
  );
};

const Book = ({ variant }) => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const methods = useForm({
    resolver: yupResolver(
      activeStep === 0
        ? Step1Schema
        : activeStep === 1
        ? Step2Schema
        : activeStep === 2
        ? Step3Schema
        : activeStep === 3
        ? Step4Schema
        : activeStep === 4
        ? Step5Schema
        : Step6Schema
    ),
    defaultValues: INITIAL_VALUES,
  });

  const [selectedValues, setSelectedValues] = useState({
    tamaño: null,
    zona: null,
    zona1: null,
    color: null,
    diseno: null,
    nombre: null,
  });

  const steps = [
    { label: "Paso 1", description: "Tamaño", content: <Step1 nextStep={nextStep} selectedValues={selectedValues} setSelectedValues={setSelectedValues} /> },
    { label: "Paso 2", description: "Zona", content: <Step2 nextStep={nextStep} selectedValues={selectedValues} setSelectedValues={setSelectedValues} /> },
    { label: "Paso 3", description: "Zona", content: <Step3 nextStep={nextStep} selectedValues={selectedValues} setSelectedValues={setSelectedValues} /> },
    { label: "Paso 4", description: "Color", content: <Step4 nextStep={nextStep} selectedValues={selectedValues} setSelectedValues={setSelectedValues} /> },
    { label: "Paso 5", description: "Descripción", content: <Step5 nextStep={nextStep} selectedValues={selectedValues} setSelectedValues={setSelectedValues} /> },
    { label: "Paso 6", description: "Datos", content: <Step6 nextStep={nextStep} selectedValues={selectedValues} setSelectedValues={setSelectedValues} /> },
  ];

  const { handleSubmit } = methods;

  const onSubmit = () => {
    if (activeStep === steps.length - 1) {
      // Envía el mensaje de WhatsApp con los datos del formulario
      const whatsappUrl = buildWhatsAppMessage(selectedValues);
      window.open(whatsappUrl, "_blank");
      console.log(selectedValues);
    } else {
      // Si no es el último paso, solo avanzamos al siguiente paso
      nextStep();
    }
  };

  const bg = useColorModeValue("gray.50", "gray.800");

  const handleReset = () => {
    reset();
    setSelectedValues(INITIAL_VALUES);
  };

  // Función para construir la URL de WhatsApp
  const buildWhatsAppMessage = (formData) => {
    // Agrega todos los datos del formulario a un mensaje predefinido
    const message = `¡Hola! 😊

    Soy ${selectedValues.nombre} y estoy interesado en hacerme un tatuaje. A continuación, te proporciono algunos detalles para que puedas brindarme más información:
    
    1. Tamaño del tatuaje que deseo:
       ${selectedValues.tamaño}
    
    2. Zona del cuerpo donde quiero el tatuaje:
       ${selectedValues.zona} - ${selectedValues.zona1}
    3. Color del tatuaje:
       ${selectedValues.color}
    
    4. Descripción del diseño que tengo en mente:
       ${selectedValues.diseno}
    
    Por favor, ¿podrías darme más detalles sobre los estilos y diseños disponibles, así como la duración y el costo aproximado? ¡Estoy emocionado por empezar! 🚀
    
    ¡Gracias!`
    
    // Codifica la URL con los datos del mensaje
    const encodedMessage = qs.stringify({ text: message });

    // Retorna la URL completa para abrir WhatsApp con el mensaje predefinido
    return `https://api.whatsapp.com/send?phone=34685892121&${encodedMessage}`;
  };

  return (
    <Box width="100%">
      <Flex
        sx={{
          flexDir: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        bg="#F7FAFC"
        mb={8}
      >
        <Image src={logo} alt="logo" width="400px" mt={8} mb={4} />
      </Flex>
      <FormProvider {...methods}>
        <Steps variant={variant} activeStep={activeStep} colorScheme="teal" pr={2} pl={2}>
          {steps.map(({ label, content, description }) => (
            <Step label={label} key={label} description={description}>
              {content}
            </Step>
          ))}
        </Steps>
      </FormProvider>
      {activeStep === steps.length ? (
        <Flex p={4} sx={{ flexDir: "column", alignItems: "center" }}>
          <Box sx={{ p: 8 }}>
            <GiRocketFlight size={64} />
          </Box>
          <Heading>¡Gracias!</Heading>
          <Box sx={{ mb: 8, mt: 4 }}>
            <Text>¿Quieres otro presupuesto?</Text>
          </Box>
          <Button mx="auto" onClick={() => handleReset()}>
            Reiniciar
          </Button>
        </Flex>
      ) : (
        <Flex width="100%" justify="center">
          <Button
            isDisabled={activeStep === 0}
            mr={4}
            onClick={prevStep}
            bg="#44c7bd"
            color="white"
          >
            Atrás
          </Button>
          {activeStep === steps.length - 1 && (
            <Button
              onClick={() => onSubmit()} // Cambiamos handleSubmit por onSubmit
              type="button"
              bg="#44c7bd"
              color="white"
              isDisabled={selectedValues.nombre?.length < 3}
            >
              Enviar
            </Button>
          )}
        </Flex>
      )}
      {/* <Box as="pre" bg={bg} rounded="md" width="100%" p={4} mt={8} color="black">
        <span>{activeStep}</span>
        <code>{JSON.stringify(selectedValues, null, 2)}</code>
      </Box> */}
    </Box>
  );
};

export default Book;
